<template>
  <el-dialog
    :show-close="false"
    class="pop"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
  >
    <div class="pop-box">
      <!-- 企业 -->
      <div class="pop-left" v-if="curDate.type == 'C'">
        <el-image
          style="width: 2vw; height: 2vw; margin-bottom: 1vw"
          src="https://oss.yanxin7.cn/2023-08-16/f748fbd24b9f994ac63815d2053e924a"
        ></el-image>
        <div style="margin-bottom: 2vw; font-weight: bolder">
          企业版核心权益
        </div>
        <div class="">
          <div class="c-gray serve-item">
            <span class="icon"></span> 资深客服一对一
          </div>
          <div class="c-gray serve-item">
            <span class="icon"></span> 3000万+工程项目信息
          </div>
          <div class="c-gray serve-item">
            <span class="icon"></span> 超10个分类可看
          </div>
          <div class="c-gray serve-item">
            <span class="icon"></span> 超50个业务可看
          </div>
        </div>
        <div class="btn">
          <el-button size="small" @click="handViewMember"
            >查看会员版本对比</el-button
          >
        </div>
      </div>
      <!-- 个人 -->
      <div class="pop-left" v-else>
        <el-image
          style="width: 2vw; height: 2vw; margin-bottom: 1vw"
          src="https://oss.yanxin7.cn/2023-08-16/f748fbd24b9f994ac63815d2053e924a"
        ></el-image>
        <div style="margin-bottom: 2vw; font-weight: bolder">
          企业版核心权益
        </div>
        <div class="">
          <div class="c-gray serve-item">
            <span class="icon"></span> 资深客服一对一
          </div>
          <div class="c-gray serve-item">
            <span class="icon"></span> 3000万+工程项目信息
          </div>
          <div class="c-gray serve-item">
            <span class="icon"></span> 超10个分类可看
          </div>
          <div class="c-gray serve-item">
            <span class="icon"></span> 超50个业务可看
          </div>
        </div>
        <div class="btn">
          <el-button size="small" @click="handViewMember"
            >查看会员版本对比</el-button
          >
        </div>
      </div>

      <div class="pop-right">
        <div class="right-header" v-if="curDate.type == 'C'">
          <div
            class="menu-item"
            @click="handItem(item, index)"
            :class="isActive == index + 1 && 'is-active'"
            v-for="(item, index) in compMemberList"
            :key="index"
          >
            {{ item.levelName }}
          </div>
          <el-image
            class="member-tuijian"
            src="https://oss.yanxin7.cn/2023-08-16/26e675b95da7bd54c4539ba464386fdd"
            style="width: 3vw; height: 1vw"
          ></el-image>
        </div>
        <div class="right-header" v-else>
          <div
            class="menu-item"
            @click="handItem(item, index)"
            :class="isActive == index + 1 && 'is-active'"
            v-for="(item, index) in persionMemberList"
            :key="index"
          >
            {{ item.levelName }}
          </div>
          <el-image
            class="member-tuijian"
            src="https://oss.yanxin7.cn/2023-08-16/26e675b95da7bd54c4539ba464386fdd"
            style="width: 3vw; height: 1vw"
          ></el-image>
        </div>

        <div class="right-content" v-if="!showView">
          <template>
            <div class="content-l">
              <div class="a-mb1 flex">
                <div class="company-name">
                  {{
                    curDate.type == "C"
                      ? userInfos.companyName
                      : userInfos.fullname
                  }}
                </div>
                <div
                  class="member-status has-member"
                  v-if="
                    curDate.type == 'C' &&
                    memberData.compMember &&
                    memberData.compMember.level
                  "
                >
                  当前是{{ curDate.type == "C" ? "企业" : "个人" }}
                  {{ memberLevel[memberData.compMember.level] }}
                  {{ memberData.compMember.endDay }}到期
                </div>
                <div
                  class="member-status"
                  v-if="curDate.type == 'C' && !memberData.compMember"
                >
                  当前暂无会员
                </div>

                <div
                  class="member-status has-member"
                  v-if="
                    curDate.type == 'P' &&
                    memberData.personMember &&
                    memberData.personMember.level
                  "
                >
                  当前是{{ curDate.type == "C" ? "企业" : "个人"
                  }}{{ memberLevel[memberData.personMember.level] }}
                  {{ memberData.personMember.endDay }}到期
                </div>
                <div
                  class="member-status"
                  v-if="curDate.type == 'P' && !memberData.compMember"
                >
                  当前暂无会员
                </div>
              </div>
              <div class="select-type a-mb1">方案选择</div>
              <div class="type-list a-mb1">
                <div
                  class="type-item"
                  @click="handTypeItem(item, index)"
                  :class="typeItmActive == index && 'type-item-active'"
                  v-for="(item, index) in allItem"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <el-image
                    class="type-tuijian"
                    v-if="index == 0"
                    src="https://oss.yanxin7.cn/2023-08-16/26e675b95da7bd54c4539ba464386fdd"
                    style="width: 3vw; height: 1vw"
                  ></el-image>
                  <div>
                    <span class="c-blue">￥</span>
                    <span class="price c-blue">{{ item.amount }}</span>
                    <!-- <span class="c-gray">/年</span> -->
                  </div>
                  <div class="center-line c-gray">￥{{ item.amount }}</div>
                </div>
              </div>
              <div class="select-type a-mb1">
                权益生效时间
                <el-image
                  style="width: 13px; height: 13px"
                  src="https://oss.yanxin7.cn/2023-08-16/a65652d596aee0b5ba1a5bc23e4f0bbe"
                ></el-image>
              </div>
              <div class="flex" style="margin-bottom: 10px">
                <el-button
                  size="small"
                  v-if="effectNow"
                  @click="getPayMemberInfo(0)"
                  class="buy-year"
                  >立即生效</el-button
                >
                <el-button
                  size="small"
                  v-if="effectAfter"
                  @click="getPayMemberInfo(1)"
                  class="buy-year"
                  >过期后生效</el-button
                >
              </div>
              <div class="b-line" style="margin-bottom: 10px"></div>
              <div class="pay-content">
                <el-image
                  style="width: 10vw; height: 7vw; margin-right: 2vw"
                  :src="srcUrl"
                ></el-image>
                <div>
                  <div class="a-mb1" style="display: flex; align-items: center">
                    <span class="c-gray a-mr10">应付金额</span>
                    <span class="c-blue">￥</span>
                    <span class="c-blue price">{{ curDate.amount }}</span>
                  </div>
                  <div class="flex a-mb1">
                    <el-image
                      style="width: 1vw; height: 1vw; margin-right: 5px"
                      src="https://oss.yanxin7.cn/2023-08-16/3933382aa614694918fec1dc0f5a2730"
                    ></el-image>
                    <span class="c-gray a-mr10 fz-12">使用微信扫码支付</span>
                  </div>
                  <div style="text-align: start">
                    <span class="c-gray fz-12">1.开通前请阅读 </span>
                    <a
                      href="https://api.yanxin7.cn/api/user/SysTerms/get?type=register"
                      target="_blank"
                      class="fz-12 c-blue"
                      style="cursor: pointer"
                      >《会员服务协议》</a
                    >
                  </div>
                  <div style="text-align: start" class="c-gray fz-12">
                    2.VIP会员自支付完成后立即生效
                  </div>
                </div>
              </div>
            </div>
            <div class="content-r">
              <div class="select-type a-mb1">会员详情</div>
              <div class="a-mb1 flex-between">
                <span class="c-gray">会员名称</span>
                <span>{{ curDate.type == "C" ? "团队版" : "个人版" }}</span>
              </div>
              <!-- <div class="a-mb1 flex-between">
                <span class="c-gray">购买时长</span>
                <span>{{ curDate.endDay }}</span>
              </div> -->
              <div class="a-mb1 flex-between">
                <span class="c-gray">会员席位</span>
                <span>子账号{{ curDate.min }}个</span>
              </div>
              <div class="a-mb1 flex-between">
                <span class="c-gray">会员到期时间</span>
                <span>{{ curDate.endDay }}</span>
              </div>
              <div class="a-mb1 flex-between">
                <span class="c-gray">订单总额</span>
                <span>￥{{ curDate.amount }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="view" v-if="showView">
          <div class="view-header">
            <div class="a-gray back-buy" @click="showView = false">
              <i class="el-icon-arrow-left"></i> 返回购买
            </div>
            <div class="member-level" style="margin-right: 100px">
              <el-image
                style="margin-right: 10px"
                src="https://oss.yanxin7.cn/2023-08-16/d7c19e0bf873e89a3697744cd231f767"
              ></el-image>
              普通会员
            </div>
            <div class="member-level" style="margin-right: 80px">
              <el-image
                style="margin-right: 10px"
                src="https://oss.yanxin7.cn/2023-08-16/d0caa4f763985d4fb9779863e8334f90"
              ></el-image>
              VIP会员
            </div>
            <div class="member-level">
              <el-image
                style="margin-right: 10px"
                src="https://oss.yanxin7.cn/2023-08-16/227aef897044a36960d4bd38c37d5cf5"
              ></el-image>
              SVIP会员
            </div>
          </div>
          <div class="product">
            <el-image
              style="width: 100%; height: 100%"
              fit="cover"
              src="https://oss.yanxin7.cn/2023-08-16/6b7ea0200e6f71c227cadf901459e311"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  memberInfoList,
  createMemberOrder,
  createORCode,
  beforeCreate,
  getOrderDetail,
  getMemberOrderDetail,
} from "@/api/member";
import { mapState } from "vuex";
import { Loading } from "element-ui";
export default {
  props: {
    memberData: {
      type: Object,
      default: {},
    },
    effectAfter: {
      type: Boolean,
      default: false,
    },
    effectNow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingInstance1: null,
      srcUrl: "",
      memberLevel: {
        1: "普通会员",
        2: "VIP会员",
        3: "SVIP会员",
      },
      allItem: [],
      showView: false,
      dialogVisible: false,
      isActive: 0,
      menuList: [
        { name: "" },
        {
          name: "个人会员",
        },
      ],
      typeItmActive: 99,
      curDate: {},
      allData: [],
      memeberType: "",
      compMemberList: [],
      persionMemberList: [],
      orderId: null,
      timerId: null,
      orderNo: null,
      memberInfo:{}
    };
  },
  computed: {
    ...mapState("account", ["userInfos"]),
  },
  mounted() {
    this.getMemberInfoList();
  },
  methods: {
    async getMemberInfoList() {
      try {
        const { data } = await memberInfoList({});
        this.compMemberList = data.compMemberList;
        this.persionMemberList = data.persionMemberList;
      } catch (error) {
        console.log("getMemberInfoListErr", error);
      }
    },
    open(row, items, isActive) {
      let newRow = this.$lodash.cloneDeep(row);
      let allItem = this.$lodash.cloneDeep(items);
      this.typeItmActive = isActive;
      this.curDate = newRow;
      this.isActive = newRow.level;
      this.allItem = allItem;
      this.dialogVisible = true;
      this.memeberType = row.type;
      this.$nextTick((res) => {
        if (this.effectNow) {
          this.getPayMemberInfo(0);
        } else if (this.effectAfter) {
          this.getPayMemberInfo(1);
        }
      });
      console.log("newRow", newRow);
      console.log("allItem", this.allItem);
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    handItem(item, index) {
      this.isActive = index + 1;
      this.allItem = item.compBuyMemberResList;
      this.typeItmActive = 99;
      console.log("itemxx", item);
    },
    async handTypeItem(item, index) {
      this.typeItmActive = index;
      this.curDate = item;
      let params = {
        id: this.curDate.id,
        compCode: this.curDate.type == "C" ? this.userInfos.creditCode : null,
      };
      const { data } = await beforeCreate(params);
      const { effectAfter, effectNow,memberInfo } = data;
      this.$nextTick(res=>{
        console.log('111111111111111111111')
        this.memberInfo = memberInfo
      })
      if (effectNow) {
        this.getPayMemberInfo(0);
      } else if (effectAfter) {
        this.getPayMemberInfo(1);
      }
    },
    handViewMember() {
      this.showView = true;
    },
    async getPayMemberInfo(e) {
      try {
        if (this.typeItmActive == 99)
          return this.$message.error("请先选择方案！");
        this.loadingInstance1 = Loading.service({ fullscreen: true });
        let params = {
          effectType: e,
          num: this.curDate.min,
          id: this.curDate.id,
        };
        if (this.curDate.type == "C") {
          params.compCode = this.userInfos.creditCode;
        }
        const { data } = await createMemberOrder(params);
        const { createTime, orderId, orderNo } = data;
        this.orderId = orderId;
        this.orderNo = orderNo;
        let payPayams = {
          createTime,
          orderId,
          orderNo,
        };
        this.newQrCode(payPayams);
      } catch (error) {
        this.loadingInstance1.close();
        this.dialogVisible = false;
      }
    },

    newQrCode(payPayams) {
      let params = {
        appid: "wx8d8633683ef1ad4a",
        jumpPath: "pages/openPay/openPay",
        sceneKeyType: "common",
        commonReq: {
          type: "caPay",
          payType: "memberPay",
          id: payPayams.orderId,
          createTime: payPayams.createTime,
          amount: this.curDate.amount,
          orderNo: payPayams.orderNo,
          creator: this.userInfos.userId,
        },
      };
      createORCode(params)
        .then((res) => {
          this.$message.success("订单创建成功,请扫码支付！");
          console.log("获取二维码结果", res);
          let blob = new Blob([res], { type: "application/image" }); // res是接口返回的文件流
          let objectUrl = URL.createObjectURL(blob);
          this.srcUrl = objectUrl;
          this.loadingInstance1.close();
          this.startTimer();
        })
        .catch((err) => {
          this.loadingInstance1.close();
          console.log("获取二维码结果err", err);
          this.loading = false;
        });
    },
    startTimer() {
      let that = this;
      this.timerId = setInterval(() => {
        that.runTimer();
      }, 3000);
    },
    stopTimer() {
      clearInterval(this.timerId);
    },
    async runTimer() {
      console.log("定时开始", new Date());
      try {
        let orderRes = await getOrderDetail({
          orderId: this.orderNo,
          queryType: 2,
          eventType: "MEMBERPAY",
          orderNo: this.orderNo,
        });
        console.log("订单状态 成功为0", orderRes.data.tradeState);
        if (orderRes.data.tradeState == 0) {
          this.$emit("callBack", {});
          this.dialogVisible = false;
          this.stopTimer();
        }
      } catch (error) {
        // this.stopTimer();
      }
    },
  },
};
</script>
<style>
.pop .el-dialog {
  background: url(https://oss.yanxin7.cn/2023-08-16/272376ea79370d93ec2cf0031d710492);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pop .el-dialog__header {
  padding: 0;
}
</style>
<style scoped>
.pop-box {
  display: flex;
  box-sizing: border-box;
}
.pop-left {
  margin-top: 3vw;
  width: 11vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2vw;
  position: relative;
  margin-right: 20px;
}
.c-gray {
  color: rgb(187, 185, 185);
}
.serve-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5vw;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
}
.icon {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 10px;
  background-color: #1e88fc;
  border-radius: 50%;
  overflow: hidden;
}
.btn {
  position: absolute;
  bottom: 10px;
}
.right-header {
  display: flex;
  width: 34vw;
  background-color: white;
  height: 3vw;
  border-radius: 35px 0 45px 0;
  position: relative;
  overflow: hidden;
}
.member-tuijian {
  position: absolute;
  left: 20px;
}
.type-tuijian {
  position: absolute;
  top: -2px;
  left: -2px;
}
.menu-item {
  cursor: pointer;
  width: 17vw;
  line-height: 3vw;
  font-weight: bold;
  color: #2288fc;
  font-size: 20px;
  text-align: center;
  border-radius: 0 0 25px 0;
}

.right-content {
  width: 48vw;
  height: 29vw;
  background-color: white;
  padding: 1vw;
  display: flex;
}
.content-l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33vw;
  border-right: 1px solid #dce5f3;
}
.content-r {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15vw;
  padding: 1vw;
}
.flex {
  display: flex;
  align-items: center;
}
.company {
  align-items: center;
}
.company-name {
  font-weight: bolder;
  font-size: 17px;
}
.member-status {
  background-color: #f3f3f3;
  font-size: 12px;
  color: #818283;
  margin-left: 10px;
  border-radius: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.has-member {
  color: #1e88fc;
  background-color: #cfe0f3;
}
.a-mb1 {
  margin-bottom: 1vw;
}
.select-type {
  font-size: 16px;
  font-weight: bold;
}
.type-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.type-item {
  width: 10vw;
  height: 5vw;
  border: 1px solid #eee;
  border-radius: 5px;
  padding-top: 15px;
  margin-right: 1vw;
  position: relative;
  cursor: pointer;
}
.type-item-active {
  background: url(https://oss.yanxin7.cn/2023-08-16/779a2f9117f301a0716ab8e2fe6cefa6);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.c-blue {
  color: #1e88fc;
}
.price {
  font-size: 20px;
  margin: 0 5px;
  font-weight: bolder;
}
.center-line {
  text-decoration: line-through;
}
.buy-year {
  padding-left: 2vw;
  padding-right: 2vw;
  margin-right: 15px;
}
.a-mb3 {
  margin-bottom: 3vw;
}
.b-line {
  border-bottom: 1px solid #dce5f3;
  width: 100%;
}
.pay-content {
  width: 31vw;
  border: 1px solid #eeeeee;
  padding: 5px;
  display: flex;
  align-items: center;
}
.a-mr10 {
  margin-right: 10px;
}
.fz-12 {
  font-size: 12px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
}
.view {
  width: 48vw;
  height: 29vw;
  overflow: auto;
  position: relative;
}
.view-header {
  display: flex;
  align-items: center;
  height: 3vw;
  background-color: white;
  position: fixed;
  width: 48vw;
  padding-left: 1vw;
  z-index: 99;
}
.back-buy {
  margin-right: 300px;
  cursor: pointer;
}
.member-level {
  font-weight: bold;
  font-size: 16px;
  margin-right: 60px;
  display: flex;
  align-items: center;
}
.member-level:last-child {
  margin-right: 0px;
}
.product {
  padding-top: 3vw;
}
.is-active {
  color: white;
  background: url(https://oss.yanxin7.cn/2023-08-16/798e381bf7a4c1ec5564dec2e76f9b11);
  background-repeat: no-repeat;
}
</style>