<template>
  <!-- 轮播图片 -->
  <div class="carousel">
    <el-carousel
      indicator-position="none"
      :height="height + 'px'"
      :type="card"
      :loop="true"
      :interval="interval"
      trigger="click"
      arrow="always"
      @change="carouselChange"
      ref="carousel"
    >
      <!-- VIP -->
      <el-carousel-item
        :width="'100px'"
        class="border1"
        :class="{
          css_left: carouseId == 1,
          css_left: carouseId == 1,
          css_right: carouseId == 2,
        }"
      >
        <div v-if="carouseId == 0">
          <div class="box-header1 box-header">
            <!-- <a class="buy-explaen" @click="handExplain">购买说明</a> -->
            <h1 class="header-title">{{ centre.levelName }}</h1>
            <p class="sub-title">小微企业超值之选</p>
          </div>
          <div>
            <div class="box-center">
              <div
                class="center-item"
                :class="isActive == index && 'is_active'"
                @click="handCenterItem(item, index)"
                v-for="(item, index) in centre.compBuyMemberResList"
                :key="index"
              >
                <div style="padding-top:10px;" class="c-gray">{{ item.name }}</div>
                <div>
                  <span class="c-blue">￥</span>
                  <span class="m-number" style="color: #2288fc">{{
                    item.amount
                  }}</span>
                  <!-- <span class="c-gray">年</span> -->
                </div>
                <div class="c-gray">{{ item.text }}</div>
                <el-image
                  class="tuijian"
                  v-if="index == 0"
                  style="width: 54px; height: 24px"
                  src="https://oss.yanxin7.cn/2023-08-16/26e675b95da7bd54c4539ba464386fdd"
                ></el-image>
              </div>
            </div>
            <el-button
              class="vip-btn"
              @click="handOPenNow(centre.compBuyMemberResList)"
              >立即开通</el-button
            >
            <p class="pay-hint">支付后可开发票</p>
          </div>
        </div>
        <div v-else>
          <div
            class="box-header-sub"
            style="
              background: url('https://oss.yanxin7.cn/2023-08-17/dc45083fa1ea9bb712c555f278969f6c');
              background-size: 100% 100%;
            "
          >
            <h1 class="header-title">{{ centre.levelName }}</h1>
            <p class="sub-title">小微企业超值之选</p>
          </div>
          <div class="lr-item">
            <div>
              <div>
                <span class="c-blue">￥</span
                ><span class="sub-number" v-if="centre.compBuyMemberResList">{{
                  centre.compBuyMemberResList[0].amount
                }}</span>
                <!-- <span class="c-gray">/年</span> -->
              </div>
              <el-button class="vip-btns">立即开通</el-button>
            </div>
            <p></p>
          </div>
        </div>
      </el-carousel-item>
      <!-- SVIP -->
      <el-carousel-item
        class="border1"
        :width="'100px'"
        :class="{
          css_right: carouseId == 0,
          css_left: carouseId == 2,
        }"
      >
        <div v-if="carouseId == 1">
          <div class="box-header2 box-header">
            <!-- <a class="buy-explaen">购买说明</a> -->
            <h1 class="header-title">{{ rigth.levelName }}</h1>
            <p class="sub-title">大型企业理想之选</p>
          </div>
          <div>
            <div class="box-center">
              <div
                class="center-item"
                :class="isActive == index && 'is_active'"
                @click="handCenterItem(item, index)"
                v-for="(item, index) in rigth.compBuyMemberResList"
                :key="index"
              >
                <div style="padding-top:10px;" class="c-gray">{{ item.name }}</div>
                <div>
                  <span class="c-blue">￥</span>
                  <span class="m-number" style="color: #2288fc">{{
                    item.amount
                  }}</span>
                  <!-- <span class="c-gray">年</span> -->
                </div>
                <div class="c-gray">{{ item.text }}</div>
                <el-image
                  class="tuijian"
                  v-if="index == 0"
                  style="width: 54px; height: 24px"
                  src="https://oss.yanxin7.cn/2023-08-16/26e675b95da7bd54c4539ba464386fdd"
                ></el-image>
              </div>
            </div>
            <el-button
              class="svip-btn"
              @click="handOPenNow(rigth.compBuyMemberResList)"
              >立即开通</el-button
            >
            <p class="pay-hint">支付后可开发票</p>
          </div>
        </div>
        <div v-else>
          <div
            class="box-header-sub"
            style="
              background: url('https://oss.yanxin7.cn/2023-08-17/9aa36166f125e6bf679c69355c8c7070');
              background-size: 100% 100%;
            "
          >
            <h1 class="header-title">{{ rigth.levelName }}</h1>
            <p class="sub-title">大型企业理想之选</p>
          </div>
          <div class="lr-item">
            <div>
              <div>
                <span class="c-blue">￥</span
                ><span class="sub-number" v-if="rigth.compBuyMemberResList">{{
                  rigth.compBuyMemberResList[0].amount
                }}</span>
                <!-- <span class="c-gray">/年</span> -->
              </div>
              <el-button class="svip-btns">立即开通</el-button>
            </div>
            <p></p>
          </div>
        </div>
      </el-carousel-item>
      <!-- BVIP -->
      <el-carousel-item
        class="border1"
        :width="'100px'"
        :class="{
          css_left: carouseId == 0,
          css_right: carouseId == 1,
        }"
      >
        <div v-if="carouseId == 2">
          <div class="box-header3 box-header">
            <!-- <a class="buy-explaen">购买说明</a> -->
            <h1 class="header-title" style="color: #713100">
              {{ left.levelName }}
            </h1>
            <p class="sub-title" style="color: #713100">小微企业经典之选</p>
          </div>
          <div>
            <div class="box-center">
              <div
                class="center-item"
                :class="isActive == index && 'is_active'"
                @click="handCenterItem(item, index)"
                v-for="(item, index) in left.compBuyMemberResList"
                :key="index"
              >
                <div style="padding-top:10px;" class="c-gray">{{ item.name }}</div>
                <div>
                  <span class="c-blue" style="color: #713100">￥</span>
                  <span class="m-number" style="color: #713100">{{
                    item.amount
                  }}</span>
                  <!-- <span class="c-gray">年</span> -->
                </div>
                <el-image
                  class="tuijian"
                  v-if="index == 0"
                  style="width: 54px; height: 24px"
                  src="https://oss.yanxin7.cn/2023-08-16/26e675b95da7bd54c4539ba464386fdd"
                ></el-image>
              </div>
            </div>
            <el-button
              class="put-btn"
              @click="handOPenNow(left.compBuyMemberResList)"
              >立即开通</el-button
            >
            <p class="pay-hint">支付后可开发票</p>
          </div>
        </div>
        <div v-else>
          <div
            class="box-header-sub"
            style="
              background: url('https://oss.yanxin7.cn/2023-08-17/61f10d86e6d4648d0bb70bb41f6d0af9');
              background-size: 100% 100% !important;
              background-repeat: no-repeat;
            "
          >
            <h1
              class="header-title"
              style="color: #713100; font-size: 36px; margin-bottom: 4px"
            >
              普通会员
            </h1>
            <p class="sub-title" style="color: #713100; font-size: 18px">
              小微企业经典之选
            </p>
          </div>
          <div class="lr-item">
            <div>
              <div>
                <span class="c-blue" style="color: #713100">￥</span
                ><span
                  class="sub-number"
                  style="color: #713100"
                  v-if="left.compBuyMemberResList"
                  >{{ left.compBuyMemberResList[0].amount }}</span
                >
                <!-- <span class="c-gray">/年</span> -->
              </div>
              <el-button class="putong-btn">立即开通</el-button>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { memberInfoList } from "@/api/member";
export default {
  name: "Carousel",
  //接收来自父组件的数据
  props: {
    // 轮播图宽度
    width: {
      type: Number,
      default: 100,
    },
    card: {
      type: String,
      default: "card",
    },
    height: {
      type: String,
      default: "500",
    },
    // 相邻两张图片切换的间隔时间
    interval: {
      type: Number,
      default: 1112000,
    },
    //图片宽
    widthImage: {
      type: Number,
      default: 100,
    },
    //图片高
    heightImage: {
      type: Number,
      default: 50,
    },
    // 轮播图路径数组
    carouselList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      isActive: 0,
      carouseId: 0,
      left: {},
      centre: {},
      rigth: {},
      allData: [],
      curentData: {},
    };
  },
  computed: {
    ...mapState("account", ["userInfos"]),
  },
  mounted() {
    this.getMemberInfoList();
  },
  methods: {
    handExplain() {
      this.$emit("handExplain");
    },
    getCurentRouterParams() {
      this.mmemberType = this.$route.query.type;
    },
    async getMemberInfoList() {
      try {
        const { data } = await memberInfoList({});
        this.allData = data;
        let mmemberType = this.$route.query.type;
          if (data.compMemberList.length > 0) {
            this.left = data.compMemberList[0] ? data.compMemberList[0] : [];
            this.centre = data.compMemberList[1] ? data.compMemberList[1] : [];
            this.rigth = data.compMemberList[2] ? data.compMemberList[2] : [];
          }
      } catch (error) {
        console.log("getMemberInfoListErr", error);
      }
    },
    carouselChange(now, old) {
      this.carouseId = now;
      this.curentData = {};
      this.isActive = 0;
    },
    handOPenNow(e) {
      if (this.curentData.id) {
        this.$emit("handCompanyMember", this.curentData, e, this.isActive);
        console.log("this.curentData", this.curentData, this.isActive);
      } else {
        this.$emit("handCompanyMember", e[0], e, this.isActive);
      }
    },
    handCenterItem(item, index) {
      this.isActive = index;
      this.curentData = item;
    },
  },
};
</script>

<style scoped>
.box-header {
  position: relative;
}
.buy-explaen {
  position: absolute;
  right: 20px;
  color: white;
}

.el-carousel {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 1400px;
}

.el-carousel__item {
  background-color: #fff;
}

.image {
  height: 550px;
}

::v-deep .el-carousel__arrow--left {
  display: none;
}
::v-deep .el-carousel__arrow--right {
  display: none;
}

.box-header-sub {
  height: 140px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 19px;
  margin-bottom: 49px;
}
.header-title {
  font-size: 40px;
  color: #ffdcc1;
  font-weight: bolder;
}
.sub-title {
  font-size: 26px;
  color: #ffdcc1;
}
.box-center {
  display: flex;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 60px;
}
.center-item {
  padding-top: 12px;
  flex-direction: column;
  display: flex;
  width: 160px;
  height: 96px;
  border: 1px solid rgb(219, 216, 216);
  position: relative;
}
.border1 {
  border: 1px solid rgb(219, 216, 216);
  border-radius: 15px;
}
.m-number {
  font-weight: bold;
  margin: 0 5px;
  font-size: 22px;
}
.c-gray {
  color: rgb(168, 166, 166);
  font-size: 14px;
}
.c-blue {
  color: #2288fc;
  font-size: 14px;
}
.tuijian {
  position: absolute;
  top: -5px;
  left: -2px;
}
.is_active {
  background: url(https://oss.yanxin7.cn/2023-08-16/779a2f9117f301a0716ab8e2fe6cefa6);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pay-hint {
  color: rgb(177, 175, 175);
  margin-top: 10px;
}
.lr-item {
  margin-top: -20px;
}
.sub-number {
  font-weight: bolder;
  color: #2288fc;
  font-size: 26px;
  margin: 0 5px;
}
.put-btn {
  font-size: 20px;
  color: #fff;
  width: 300px;
  height: 50px;
  color: #713100 !important;
  background: linear-gradient(to right, #ffe89f, #fad355);
}
.vip-btn {
  font-size: 20px;
  color: #fff;
  width: 300px;
  height: 50px;
  background: linear-gradient(to right, #7e84af, #1c2145);
}
.svip-btn {
  font-size: 20px;
  color: #fff;
  width: 300px;
  height: 50px;
  background: linear-gradient(to right, #7e9ae2, #1349d2);
}
.box-header1 {
  margin-bottom: 49px;
  padding-top: 19px;
  height: 180px;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background: url("https://oss.yanxin7.cn/2023-08-17/dc45083fa1ea9bb712c555f278969f6c");
}
.box-header2 {
  margin-bottom: 49px;
  padding-top: 19px;
  height: 180px;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background: url("https://oss.yanxin7.cn/2023-08-17/9aa36166f125e6bf679c69355c8c7070");
}
.box-header3 {
  color: #713100;
  margin-bottom: 49px;
  padding-top: 19px;
  height: 180px;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background: url("https://oss.yanxin7.cn/2023-08-17/61f10d86e6d4648d0bb70bb41f6d0af9");
}
.putong-btn {
  color: #713100 !important;
  background: linear-gradient(to right, #ffe89f, #fad355);
  font-weight: bolder;
  margin-top: 20px;
  font-size: 20px;
  color: #fff;
  width: 142px;
  height: 50px;
}
.vip-btns {
  margin-top: 20px;

  font-size: 20px;
  color: #fff;
  width: 142px;
  height: 50px;
  background: linear-gradient(to right, #7e84af, #1c2145);
}
.svip-btns {
  margin-top: 20px;
  font-size: 20px;
  color: #fff;
  width: 142px;
  height: 50px;
  background: linear-gradient(to right, #7e9ae2, #1349d2);
}
.css_left {
  width: 400px;
  margin-left: 35px;
  margin-top: 65px;
  height: 300px !important;
  border: 1px solid rgb(190, 188, 188);
  border-radius: 10px;
}
@keyframes ball {
  0% {
    margin-top: 60px;
  }
  25% {
  }
  50% {
  }
  75% {
    margin-top: 80px;
  }
  100% {
  }
}
.css_right {
  width: 400px;
  margin-left: 265px;
  margin-top: 65px;
  height: 300px !important;
  border: 1px solid rgb(190, 188, 188);
  border-radius: 10px;
  transition: all 0.5s !important;
}
/* .css_right:hover {
  animation-name: ball;
  animation-duration: 1s;
} */
</style>