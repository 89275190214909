<template>
  <div style="min-width: 1440px; overflow: auto">
    <div class="m-header">
      <div class="flex-base" :class="mmemberType == 'company' ? 'flex-base1' : 'flex-base2'">
        <h1 class="bjt-title">
          邦建通{{ mmemberType == "company" ? "企业" : "个人" }}套餐
        </h1>
        <a class="title-hint" @click="handChangeMember">购买{{ mmemberType == "company" ? "个人" : "企业" }}会员</a>
      </div>
      <h3 class="sub-title">
        {{
          mmemberType == "company"
          ? "批量开通会员 团队工作更高效"
          : "更多权益 超多特权"
        }}
      </h3>
    </div>
    <div class="m-center-box">
      <div class="m-swiper-box">
        <swiper @handCompanyMember="handCompanyMember" @handExplain="handExplain" v-show="mmemberType == 'company'">
        </swiper>
        <swiperPerson @handPersonMember="handPersonMember" v-show="mmemberType !== 'company'"></swiperPerson>
      </div>
      <div>
        <h1 class="bjt-title">权益对比介绍</h1>
        <div class="sub-title">了解权益明细 提高工作效率</div>
      </div>

      <div class="btn-list">
        <el-button @click="handItemService(item)" v-for="(item, index) in serviceList" :key="index" plain
          class="btn-item">{{ item.name }}</el-button>
      </div>

      <div class="member-header" id="explains">
        <div class="explain">服务说明</div>
        <template v-if="mmemberType == 'company'">
        <div class="explain-item">
          <div class="item-title">{{compMemberList[0].compBuyMemberResList[0].name}}</div>
          <div>
            <span class="c-gray">￥</span>
            <span class="fw-b">{{compMemberList[0].compBuyMemberResList[0].amount}}</span>
            <!-- <span class="c-gray">元/年</span> -->
          </div>

          <div style="margin-top: 15px">
            <!-- <el-button @click="handItemBuy()" plain class="btn-item"
              >购买使用</el-button
            > -->
          </div>
        </div>
        <div class="explain-item">
          <div class="item-title">{{compMemberList[1].compBuyMemberResList[0].name}}</div>
          <div>
            <span class="c-gray">￥</span>
            <span class="fw-b">{{compMemberList[1].compBuyMemberResList[0].amount}}</span>
            <!-- <span class="c-gray">元/年</span> -->
          </div>

          <div style="margin-top: 15px">
            <!-- <el-button @click="handItemBuy()" plain class="btn-item"
              >购买使用</el-button
            > -->
          </div>
        </div>
        <div class="explain-item">
          <div class="item-title">{{compMemberList[2].compBuyMemberResList[0].name}}</div>
          <div>
            <span class="c-gray">￥</span>
            <span class="fw-b">{{compMemberList[2].compBuyMemberResList[0].amount}}</span>
            <!-- <span class="c-gray">元/年</span> -->
          </div>
          <div style="margin-top: 15px">
            <!-- <el-button @click="handItemBuy()" plain class="btn-item"
              >购买使用</el-button
            > -->
          </div>
        </div>
      </template>
      <template v-else>
        <div class="explain-item">
          <div class="item-title">{{persionMemberList[0].compBuyMemberResList[0].name}}</div>
          <div>
            <span class="c-gray">￥</span>
            <span class="fw-b">{{persionMemberList[0].compBuyMemberResList[0].amount}}</span>
            <!-- <span class="c-gray">元/年</span> -->
          </div>

          <div style="margin-top: 15px">
            <!-- <el-button @click="handItemBuy()" plain class="btn-item"
              >购买使用</el-button
            > -->
          </div>
        </div>
        <div class="explain-item">
          <div class="item-title">{{persionMemberList[1].compBuyMemberResList[0].name}}</div>
          <div>
            <span class="c-gray">￥</span>
            <span class="fw-b">{{persionMemberList[1].compBuyMemberResList[0].amount}}</span>
            <!-- <span class="c-gray">元/年</span> -->
          </div>

          <div style="margin-top: 15px">
            <!-- <el-button @click="handItemBuy()" plain class="btn-item"
              >购买使用</el-button
            > -->
          </div>
        </div>
        <div class="explain-item">
          <div class="item-title">{{persionMemberList[2].compBuyMemberResList[0].name}}</div>
          <div>
            <span class="c-gray">￥</span>
            <span class="fw-b">{{persionMemberList[2].compBuyMemberResList[0].amount}}</span>
            <!-- <span class="c-gray">元/年</span> -->
          </div>
          <div style="margin-top: 15px">
            <!-- <el-button @click="handItemBuy()" plain class="btn-item"
              >购买使用</el-button
            > -->
          </div>
        </div>
      </template>
      </div>


      <el-image style="width: 62vw; height: 100%" fit="cover"
        src="https://oss.yanxin7.cn/2023-08-16/6b7ea0200e6f71c227cadf901459e311"></el-image>
    </div>
    <buyMemberVue :effectAfter="effectAfter" :effectNow="effectNow" :memberData="memberData" @callBack="callBack()"
      ref="memberBuy"></buyMemberVue>
    <login-form v-if="showLoginForm" :routeRedirect="routeRedirect" @loginSuccess="handleLoginSuccess"
      @close="handleLoginClose" />
  </div>
</template>
<script>
import LoginForm from "@/components/login-form";
import swiper from "./components/swiper.vue";
import swiperPerson from "./components/personSwiper.vue";
import { isMember, beforeCreate,memberInfoList } from "@/api/member";
import { urlCommonColumnList } from "@/api/engineering";
import { mapState } from "vuex";
import buyMemberVue from "./components/buyMember.vue";
import { Loading } from "element-ui";
import Utils from "@src/utils/shift.js";
export default {
  components: {
    buyMemberVue,
    swiper,
    swiperPerson,
    LoginForm,
  },
  data() {
    return {
      mmemberType: "company",
      serviceList: [],
      memberData: {},
      effectAfter: false,
      effectNow: false,
      showLoginForm: false,
      compMemberList:[],
      persionMemberList:[]
    };
  },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  mounted() {
    document.title = "购买会员";
    let that = this;
    Utils.$on("changMemberType", function (msg) {
      that.mmemberType = msg;
    });
    this.geturlCommonColumnList();
    this.getCurentRouterParams();
    this.getIsMember();
    this.getMemberInfoList()
  },
  methods: {
    async getMemberInfoList() {
      try {
        const { data } = await memberInfoList({});
        this.compMemberList = data.compMemberList;
        this.persionMemberList = data.persionMemberList;

        console.log("getMemberInfoListdata", data);

      } catch (error) {
        console.log("getMemberInfoListErr", error);
      }
    },
    handExplain() {
      const mainElement = document.getElementById("explains");
      console.log('mainElement', mainElement.offsetTop)
      document.body.scrollTop = 10000;
      mainElement.scrollTo({

        top: 110,
        behavior: "smooth",
      });
    },
    handleLoginSuccess(toPage) {
      this.showLoginForm = false;
      location.reload()

    },
    handleLoginClose() {
      this.showLoginForm = false;
    },
    callBack() {
      this.$message.success("订单支付成功！");
      if (window?.opener) {
        window.opener.postMessage("updateMemberData", "*")
      }
      setTimeout(() => {
        location.reload();
      }, 2000);
    },
    async handCompanyMember(e, item, isActive) {
      if (!this.userInfos || !this.token) {
        this.showLoginForm = true;
        return this.$message.error("请先登录！");
      }
      if (!this.userInfos.creditCode) return this.$message.error('您还未注册有公司，请到邦建通小程序入驻企业')
      let loadingInstance1 = Loading.service({ fullscreen: true });
      let that = this;
      try {
        const { data } = await beforeCreate({
          id: e.id,
          compCode: this.userInfos.creditCode,
        });
        const { effectAfter, effectNow, memberInfo } = data;
        this.$nextTick(res => {
          that.$refs.memberBuy.memberInfo = memberInfo
        })
        this.effectAfter = effectAfter;
        this.effectNow = effectNow;
        if (data.enableBuy) {
          that.$refs.memberBuy.open(e, item, isActive);
        } else {
          this.$message.success(data.msg);
        }
        loadingInstance1.close();

        console.log("beforeCreate", data);
      } catch (error) {
        loadingInstance1.close();
        console.log("beforeCreateerror", error);
      }
    },
    async handPersonMember(e, item, isActive) {
      if (!this.userInfos || !this.token) {
        this.showLoginForm = true;
        return this.$message.error("请先登录！");
      }
      let loadingInstance1 = Loading.service({ fullscreen: true });

      let that = this;
      try {
        const { data } = await beforeCreate({
          id: e.id,
          userId: this.userInfos.userId,
        });
        const { effectAfter, effectNow, memberInfo } = data;
        this.$nextTick(res => {
          that.$refs.memberBuy.memberInfo = memberInfo
        })
        this.effectAfter = effectAfter;
        this.effectNow = effectNow;
        if (data.enableBuy) {
          that.$refs.memberBuy.open(e, item, isActive);
        } else {
          this.$message.success(data.msg);
        }
        loadingInstance1.close();
        console.log("beforeCreatesucc", data);
      } catch (error) {
        loadingInstance1.close();
        console.log("beforeCreateerror", error);
      }
    },
    getCurentRouterParams() {
      this.mmemberType = this.$route.query.type;
    },
    // 确认是否是会员
    async getIsMember() {
      if (this.userInfos) {
        try {
          const { data } = await isMember({
            compCode: this.userInfos.creditCode,
          });
          this.memberData = data;
          console.log("memData", this.memData);
        } catch (error) {
          console.log("isMember", error);
        }
      }
    },
    handItemService(item) {
      window.open(item.params);
    },
    handItemBuy(e) {
      console.log("eexxs", e);
      this.$refs.memberBuy.dialogVisible = true;
    },
    handItem(e) {
      console.log("xxx", e);
    },
    // 获取建筑行业服务列表
    async geturlCommonColumnList() {
      if (!this.userInfos.userId) return
      try {
        const { data } = await urlCommonColumnList({
          appid: "pcd87133b3134a7dff",
        });
        this.$nextTick(() => {
          this.serviceList = data;
        });
      } catch (error) {
        console.log("ee", error);
      }
    },
    // 切换会员
    handChangeMember() {
      if (this.mmemberType == "company") {
        this.mmemberType = "person";
      } else {
        this.mmemberType = "company";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.m-header {
  // padding-top: 2vw;
  padding-top: 25px;
  height: 19vw;
  background: url(https://oss.yanxin7.cn/2023-08-16/7df957db8b200a5c5cdfeee8c941b728);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bjt-title {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 10px;
  text-align: center !important;
}

.sub-title {
  font-size: 28px;
}

.m-center-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -170px;
  padding-bottom: 5vw;
}

.m-swiper-box {
  margin-bottom: 2vw;
}

.btn-item {
  width: 110px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.btn-item:hover {
  background-color: #eef6ff;
}

.btn-item:nth-child(10n) {
  margin-right: 0px;
}

.btn-list {
  margin-top: 2vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 62vw;
  justify-content: flex-start;
}

.el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 0;
}

.member-header {
  width: 62vw;
  border: 1px solid #dce5f2;
  display: flex;
  align-items: center;
}

.explain {
  width: 25vw;
  text-align: start;
  border-right: 1px solid #dce5f2;
  height: 9vw;
  line-height: 9vw;
  font-size: 36px;
  font-weight: bold;
  padding-left: 20px;
  box-sizing: border-box;
}

.explain-item {
  width: 12.5vw;
  border-right: 1px solid #dce5f2;
  height: 9vw;
  box-sizing: border-box;
  padding-top: 25px;
}

.explain-item:last-child {
  border-right: none;
}

.item-title {
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.c-gray {
  color: rgb(170, 168, 168);
}

.fw-b {
  font-weight: bold;
  font-size: 32px;
  margin: 0 10px;
}

.flex-base {
  align-items: baseline;
  justify-content: center;
  display: flex;
  position: relative;
  padding-left: 100px;

  a {
    right: 0;
    border-bottom: 1px solid #2288fc;
    padding-bottom: 1px;
    color: #2288fc;
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>